.validate-comde-page-text {
    position: absolute;
    width: 633px;
    height: 42px;
    left: 396px;
    top: 373px;
    
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.05em;
    
    color: #5f5f5f;
}

.validate-code-page-fields-container {
    position: absolute;
    display: flex;
    left: 389px;
    top: 477px;
    width: 633px;
    height: 70px;
    justify-content: space-between;
}

.validate-code-page-text-field-container {
    left: 389px;
    top: 477px;
    width: 67px;
    height: 70px;
    background: #FBF3F9;
    background-color: red;
}