  .zuku-fill-btn {
    width: 130px;
    height: 43px;
    background-color: rgba(179, 16, 236, 1);
    color: white;
    font-family: Roboto;
    font-weight: 300;
    font-size: 20px;
    line-height: normal;
    font-family: 'Roboto', sans-serif;
  }