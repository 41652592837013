.zuku-textfield {
    height: 70px;
    color: rgba(38, 38, 38, 1);
    background-color: rgba(251, 243, 249, 1);
    text-align: center;
    font-size: 18px;
    font-family: Roboto;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 0.05em;
    width: 100%;
  }